import * as React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { redirectOnDomainMatch } from './redirect-on-domain-match';
import 'assets/css/fonts.css';
import './index.css';

redirectOnDomainMatch(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
});
