import {
  Box,
  css,
  Flex,
  genesisStyled,
  QuietButton,
  SubtleLink,
} from '@leagueplatform/genesis-commons';
import React, { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { ParagraphText } from '@leagueplatform/genesis-core';
import { LegalModal } from './legal-modal.component';

const DisclaimerContent = genesisStyled(Box)(
  css({
    '&& > *': {
      marginBottom: 'one',
    },
    '&& > :last-child': {
      marginBottom: '0',
    },
  }),
);

const UnstyledLinkButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: any;
}) => (
  <SubtleLink
    as={QuietButton}
    hoverStyle={{
      backgroundColor: 'transparent !important',
    }}
    target="_blank"
    rel="noopener noreferrer"
    border="none"
    fontSize="body2"
    color="tertiary.text.default"
    onClick={onClick}
  >
    {children}
  </SubtleLink>
);

export const Disclaimer = ({
  disclaimer = '',
  hasLegalModal = false,
}: {
  disclaimer?: string;
  hasLegalModal?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [showLegalModal, setShowModal] = useState<Boolean>(false);

  return (
    <Flex>
      <DisclaimerContent>
        {disclaimer
          .split('\n')
          .filter((text) => text !== '') // Remove any empty strings, sometimes there's double /n in text.
          .map((text) => (
            <ParagraphText size="sm" key={text}>
              {text}
            </ParagraphText>
          ))}
        {hasLegalModal && (
          <UnstyledLinkButton onClick={() => setShowModal(true)}>
            {formatMessage({ id: 'READ_MORE' })}
          </UnstyledLinkButton>
        )}
      </DisclaimerContent>
      {hasLegalModal && (
        <LegalModal
          isOpen={showLegalModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </Flex>
  );
};
