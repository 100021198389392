interface LiveChatFlagsProps {
  liveChatNavigateAway?: boolean;
}

const defaultValues = {
  shouldShowNavigateAway: false,
};

export const getLiveChatFirebaseConfig = ({
  liveChatNavigateAway,
}: LiveChatFlagsProps) => ({
  ...defaultValues,
  ...{
    shouldShowNavigateAway: liveChatNavigateAway,
  },
});
