import {
  APP_MANAGED_ROUTES,
  FooterLinkProps,
  highmarkCommon,
  useGetAppInitDetails,
  useIsAuthenticated,
} from '@highmark-web/highmark-common';
import { useIntl } from '@leagueplatform/locales';

export const useFooterLinks = () => {
  const { formatMessage } = useIntl();

  const { isAuthenticated } = useIsAuthenticated();
  const { eligibilityInfo } = useGetAppInitDetails({
    enabled: !!isAuthenticated,
  });

  const legalUrl = APP_MANAGED_ROUTES.LEGAL;

  const { highmarkMemberPortalUrl, highmarkContentUrl, qualtricsSurveyUrl } =
    highmarkCommon.getConfig();

  const isPostAuth = isAuthenticated && eligibilityInfo?.eligible;

  const footerLinks: FooterLinkProps[] = isPostAuth
    ? [
        {
          text: formatMessage({ id: 'LEGAL' }),
          url: legalUrl,
          linkProps: {
            target: '_self',
          },
        },
        {
          text: formatMessage({ id: 'ALL_LANGUAGES' }),
          url: APP_MANAGED_ROUTES.TECHNICAL_ASSISTANCE_AND_ALL_LANGUAGES,
          linkProps: {
            target: '_self',
          },
        },
        {
          text: formatMessage({ id: 'GIVE_FEEDBACK' }),
          url: qualtricsSurveyUrl,
          linkProps: {
            target: '_blank',
          },
        },
      ]
    : [
        {
          text: formatMessage({ id: 'LEGAL' }),
          url: `${highmarkMemberPortalUrl}/pre-password-legal`,
        },
        {
          text: formatMessage({ id: 'ALL_LANGUAGES' }),
          url: `${highmarkMemberPortalUrl}/pre-password-language`,
        },
        {
          text: formatMessage({ id: 'NON_DISCRIMINATION_POLICY' }),
          url: `${highmarkContentUrl}/content/highmarkblueshield/policies/non-discrimination/policystatement-cpa.pdf`,
        },
      ];

  return { footerLinks };
};
