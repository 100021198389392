interface WalletFlagsProps {
  walletLiveBalances?: boolean;
}

const defaultValues = {
  enableNoNetworkLiveBalances: false,
};

export const getWalletFirebaseConfig = ({
  walletLiveBalances,
}: WalletFlagsProps) => ({
  ...defaultValues,
  ...{
    enableNoNetworkLiveBalances: walletLiveBalances,
  },
});
