import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { InternalOrExternalLink } from '@leagueplatform/web-common-components';
import { GDSStyleObject } from '@leagueplatform/genesis-core';
import React, { AnchorHTMLAttributes } from 'react';
import { useIsAuthenticated } from 'hooks/use-is-authenticated';

export interface FooterLinkProps {
  text: string;
  url?: string;
  onClick?: () => void;
  linkProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
}

export const FooterLinkItems = ({
  linkItems,
  styleProps,
}: {
  linkItems: FooterLinkProps[];
  styleProps?: GDSStyleObject;
}) => {
  const { isAuthenticated } = useIsAuthenticated();

  const trackAnalytics = (eventName: EVENT_NAME, targetDetail: string) => {
    trackAnalyticsEvent(eventName, {
      detail: targetDetail,
      sub_product_area: 'footer',
    });
  };

  const handleClick = (event: React.MouseEvent, item: FooterLinkProps) => {
    if (isAuthenticated) {
      trackAnalytics(
        item.url ? EVENT_NAME.RESOURCE_CLICKED : EVENT_NAME.BUTTON_CLICKED,
        item.text,
      );
    }
    if (item.onClick) {
      event.preventDefault();
      item.onClick();
    }
  };

  return (
    <>
      {linkItems.map((item) =>
        item.url ? (
          <InternalOrExternalLink
            css={{
              border: 'none',
              marginX: '$threeQuarters',
              fontSize: '$bodyTwo',
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
              // eslint-disable-next-line react/jsx-props-no-spreading
              ...styleProps,
            }}
            key={item.text}
            href={item.url}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              handleClick(event, item)
            }
            target="_blank"
            rel="noopener noreferrer"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...item.linkProps}
          >
            {item.text}
          </InternalOrExternalLink>
        ) : null,
      )}
    </>
  );
};
