import React from 'react';
import { PrivateBasePage } from 'components/private-base-page.component';
import {
  APP_MANAGED_ROUTES,
  ERROR_TYPES,
  ErrorState,
  INELIGIBLE_CODES,
  LegalPage,
  LoadingSpinner,
  MfaEnrollment,
  PageEligibilityContext,
  RegionSelectionContext,
  useAnalyticsIdentify,
  usePrivateRoutesContainer,
  UserTypeContext,
} from '@highmark-web/highmark-common';
import { RewardsEventController } from '@leagueplatform/rewards-events';
import { useFeatureFlagQuery } from '@leagueplatform/user-profile-api';
import {
  ACHIEVEMENTS_ENABLED,
  REWARDS_EVENTS_ENABLED,
} from '@leagueplatform/rewards-api';
import { useConfigProperty } from '@leagueplatform/core';
import { Route, Switch } from '@leagueplatform/routing';
import BoxoutIcon from 'assets/boxout.svg';
import { WalkthroughContainer } from './app-walkthrough/walkthrough-container.component';
import { useDynamicConfig } from '../config/use-dynamic-config.hook';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    contentConfig,
    eligibilityInfo,
    regionSelection,
    enableLiveChat,
    showWalkthrough,
    onCompleteWalkthrough,
    qualifiers,
    showMfa,
    onCompleteMfa,
    isLoading,
    isErrorAppInitDetails,
    isErrorUserProfile,
  } = usePrivateRoutesContainer();

  const beneficityUrl = import.meta.env.VITE_BENEFICITY_APP_LOGIN_URL as string;
  const allMyHealthUrl = import.meta.env.VITE_AMH_APP_LOGIN_URL as string;

  const userTypeDetails = React.useMemo(
    () => ({
      isEasyPlanUser: qualifiers?.isEasyPlanUser || false,
    }),
    [qualifiers?.isEasyPlanUser],
  );

  // Dynamically update SDK config to load Firebase and API-dependent values
  useDynamicConfig(qualifiers?.childrensHealthInsuranceProgramUser);

  const { data: isRewardsLayerEnabled } = useFeatureFlagQuery(
    REWARDS_EVENTS_ENABLED,
  );

  const { data: isAchievementsEnabled } =
    useFeatureFlagQuery(ACHIEVEMENTS_ENABLED);

  // Send user data to analytics SDK
  const tenantId = useConfigProperty('core.tenantId');
  useAnalyticsIdentify(tenantId!);

  if (isLoading) return <LoadingSpinner />;

  if (isErrorAppInitDetails) {
    return <ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />;
  }

  // Show not eligible screen if user isn't eligible to use the app
  if (!eligibilityInfo.eligible) {
    // Show specific not eligible screen if user hasn't had coverage for over 365 days
    switch (eligibilityInfo.reasonCode) {
      case INELIGIBLE_CODES.OVER_365_DAYS:
        return <ErrorState isFullPage errorType={ERROR_TYPES.OVER_365_DAYS} />;
      case 7:
        return (
          <ErrorState
            isFullPage
            imageSrc={BoxoutIcon}
            buttonLink={allMyHealthUrl}
            errorType={ERROR_TYPES.NOT_ELIGIBLE_ALLMYHEALTH}
          />
        );
      case 14:
        return (
          <ErrorState
            isFullPage
            imageSrc={BoxoutIcon}
            buttonLink={beneficityUrl}
            errorType={ERROR_TYPES.NOT_ELIGIBLE_BENEFICITY}
          />
        );
      default:
        return <ErrorState isFullPage errorType={ERROR_TYPES.NOT_ELIGIBLE} />;
    }
  }

  // Show error screen if user is eligible, but userProfile API fails
  if (isErrorUserProfile)
    return <ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />;

  // Show walkthrough slides if needed
  if (showWalkthrough) {
    return (
      <Switch>
        <Route path={APP_MANAGED_ROUTES.LEGAL} component={LegalPage} />
        <Route>
          <WalkthroughContainer onCompleteWalkthrough={onCompleteWalkthrough} />
        </Route>
      </Switch>
    );
  }

  if (showMfa) {
    return <MfaEnrollment onEnrollSuccess={onCompleteMfa} />;
  }

  return (
    <UserTypeContext.Provider value={userTypeDetails}>
      <RegionSelectionContext.Provider value={regionSelection}>
        <PrivateBasePage enableLiveChat={enableLiveChat}>
          {isRewardsLayerEnabled && isAchievementsEnabled && (
            <RewardsEventController />
          )}
          <PageEligibilityContext.Provider value={contentConfig}>
            {children}
          </PageEligibilityContext.Provider>
        </PrivateBasePage>
      </RegionSelectionContext.Provider>
    </UserTypeContext.Provider>
  );
};
