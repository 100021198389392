import Masonry from '@leagueplatform/masonry';
import { SCREEN_NAMES, usePageViewAnalytics } from '@leagueplatform/analytics';
import React, { useContext } from 'react';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { MASONRY } from 'common/constants';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { MasonryPageContainer } from 'pages/masonry/masonry-container.page';
import { UserTypeContext } from 'components/user-type-context/user-type-context.component';

const masonryConfig = {
  appId: MASONRY.APP_ID_SUPPORT_PAGE,
  analytics: {
    productArea: SCREEN_NAMES.SUPPORT,
    screenName: SCREEN_NAMES.SUPPORT,
  },
  uiConfig: {
    CustomLoader: () => <LoadingSpinner />,
  },
  apiVersion: MASONRY.API_VERSION,
};

export const SupportPage = () => {
  usePageViewAnalytics({ screen_name: SCREEN_NAMES.SUPPORT });
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'SUPPORT_PAGE' }));
  const { isEasyPlanUser } = useContext(UserTypeContext);

  // useMemo since we need to generate the component inside another component for dynamic values but want to avoid re-renders
  const SupportPageMasonry = React.useMemo(
    () =>
      Masonry.init({
        ...masonryConfig,
        analytics: {
          ...masonryConfig.analytics,
          ...(isEasyPlanUser ? { subDetail: 'is_easy_plan_user' } : {}),
        },
      }),
    [isEasyPlanUser],
  );

  return (
    <MasonryPageContainer>
      <SupportPageMasonry.Provider />
    </MasonryPageContainer>
  );
};
