import { FeatureFlag, useGetFeatureFlag } from '@highmark-web/highmark-common';
import { firebaseRemoteConfig } from '../common/firebase/firebase';

// wrapper hook to pass app-specific values to module-level hook
export const useGetAppFeatureFlag = (flagName: string): FeatureFlag => {
  const { isEnabled, value } = useGetFeatureFlag({
    flagName,
    remoteConfig: firebaseRemoteConfig,
  });

  return { isEnabled, value };
};
